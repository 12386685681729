<!--
 * @Author: wh
 * @Date: 2020-08-04 19:43:12
 * @LastEditTime: 2022-01-13 19:11:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \JT_Web_test\src\views\weighRoomManage\appointOrder\index.vue
-->
<template>
    <div class="appointOrder">
        <ul class="stationList">
            <li
                v-for="item in stationList"
                :key="item.station_id"
                :class="{'active':stationIds.includes(item.station_id)}"
                @click="selectStation(item)"
            >
                {{ item.name }}
            </li>
        </ul>
        <defaultPage v-if="showDefault" @handleRefresh="refresh"></defaultPage>
        <list :data="dataList"></list>
    </div>
</template>

<script>
import defaultPage from './default';
import list from './list';
import { eventBus } from '@/common/eventBus.ts';
export default {
    components: { defaultPage, list },
    props: {},
    data() {
        return {
            showDefault: false,
            dataList: [],
            stationIds: [],
            stationList: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        eventBus.$on('TasklcClashAddMessage', data => {
            const StationId = this.$takeTokenParameters('StationId');
            if (StationId) {
                if (StationId === data.mixstation_code) {
                    const arr = this.dataList.filter(item => item.id === data.id);
                    if (arr.length < 1) {
                        this.dataList.push(data);
                    } else {
                        const index = this.dataList.findIndex(item => item.id === data.id);
                        this.dataList.splice(index, 1, data);
                    }
                }
            } else {
                const arr = this.dataList.filter(item => item.id === data.id);
                if (arr.length < 1) {
                    this.dataList.push(data);
                } else {
                    const index = this.dataList.findIndex(item => item.id === data.id);
                    this.dataList.splice(index, 1, data);
                }
            }
            if (this.dataList.length > 0) {
                this.showDefault = false;
            } else {
                this.showDefault = true;
            }
        });
        this.initData();
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('TasklcClashAddMessage');
    },
    methods: {
        async initData() {
            await this.getStationList();
            if (this.stationList.length > 0) {
                this.stationIds = [this.stationList[0].station_id];
            }
            const StationId = this.$takeTokenParameters('StationId');
            if (StationId) {
                this.stationIds = StationId;
            } else {
                this.stationIds = this.stationList[0].station_id || '';
            }
            this.getTruckClashs();

        },
        async refresh() {
            const res = await this.getTruckClashsApi();
            if (res) {
                if (res.length < 1) {
                    this.showDefault = true;
                    this.$message.warning('暂无数据！');
                } else {
                    this.showDefault = false;
                    this.dataList = res;
                }
            }
        },
        async getTruckClashs() {
            const res = await this.getTruckClashsApi();
            if (res) {
                if (res.length < 1) {
                    this.showDefault = true;
                } else {
                    this.showDefault = false;
                    this.dataList = res;
                }
            }
        },

        // 获取原材料车辆与供应单冲突信息集合
        getTruckClashsApi() {
            const path = '/interfaceApi/procurement/daily/supply/get_truck_clashs?stationIds=' + this.stationIds;
            return this.$axios.$get(path, { defEx: true });
        },

        selectStation(item) {
            this.stationIds = item.station_id;
            this.refresh();
        },
        // 获取场站列表
        async getStationList() {
            this.stationList = [];
            const station_Names = this.$takeTokenParameters('StationNames').split(',');
            const station_IDs = this.$takeTokenParameters('StationIds').split(',');
            if ((station_Names.length || []) > 0) {
                station_Names.forEach((item, index) => {
                    this.stationList.push({
                        name: item,
                        station_id: station_IDs[index],
                    });
                });
            }
        },
    },
};
</script>
<style lang="stylus">
.appointOrder
    height 100%
    .stationList
        width 100%;
        display flex
        background #fff;
        padding-top 0.2rem;
        padding-left 0.2rem
        li
            margin-right: 0.15rem;
            padding: 0.06rem 0.15rem;
            border: 1px solid #eaeefb;
            color: #333;
            font-size: 0.16rem;
            cursor: pointer;
            &.active
                border 1px solid #4A97E5
                color #4A97E5
    .appointOrder-default
        height calc(100% - 0.6rem)
</style>
