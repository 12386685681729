<!--
 * @Author: wh
 * @Date: 2020-08-04 20:26:56
 * @LastEditTime: 2021-05-12 11:51:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \Web\JT.Web\src\views\weighRoomManage\appointOrder\list.vue
-->
<template>
    <div class="appointOrder-list">
        <div class="appointOrder-list-content">
            <div class="cars">
                <div
                    class="car"
                    :class="{'active': activeCar===item.id}"
                    v-for="(item) in data"
                    :key="`index_${item.truck_id}`"
                    @click="selectCar(item.id,item)"
                >
                    <ul>
                        <li class="first">
                            <span class="iconfont iconchepai"></span>
                            <span>车牌号：{{ item.plate_number }}</span>
                        </li>
                        <li>
                            <span class="iconfont icondriver"></span>
                            <span>司机：{{ item.driver_name }}</span>
                        </li>
                        <li>
                            <span class="iconfont icondianhua"></span>
                            <span>电话：{{ item.driver_phone }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="list">
                <ul>
                    <li class="tit">
                        <span class="first-td">选择</span>
                        <span class="flex2">供应单号</span>
                        <span class="flex2">供应商</span>
                        <span class="flex2">运输单位</span>
                        <span>原材料分类</span>
                        <span>原材料子类</span>
                        <span>原材料规格</span>
                        <span>供应量</span>
                        <span>供应日期</span>
                    </li>
                    <li
                        v-for="item in supplyList"
                        :key="item.id"
                        :class="{ 'active': isActive(item.no) }"
                        @click="selectSupplyOrder(item.no)"
                    >
                        <span class="chose first-td">
                            <span class="iconfont iconqueding"></span>
                        </span>
                        <span class="flex2" :title="item.no">{{ item.no }}</span>
                        <span class="flex2" :title="item.supplier_name">{{ item.supplier_name }}</span>
                        <span class="flex2" :title="item.transport_name">{{ item.transport_name }}</span>
                        <span :title="item.type_name">{{ item.type_name }}</span>
                        <span :title="item.goods_name">{{ item.goods_name }}</span>
                        <span :title="item.spec_name">{{ item.spec_name }}</span>
                        <span :title="item.supply_count">{{ item.supply_count }}</span>
                        <span :title="item.supply_date">{{ item.supply_date }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="btn-con">
            <span class="sure-btn" @click="setRelation">确定</span>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        data: {
            type: Array,
        },
    },
    data() {
        return {
            activeCar: 0,
            supplyList: [],
            selectedOrderNo: '',

        };
    },
    watch: {
        data() {
            if (this.data) {
                this.supplyList = this.data[0].supplyList;
                this.activeCar = this.data[0].id;
            }
        },
    },
    computed: {},
    created() {
    },
    mounted() {

    },
    methods: {
        // 选中车辆
        selectCar(index, data) {
            this.activeCar = index;
            this.supplyList = data.supplyList;
        },
        // 任务单选中
        selectSupplyOrder(no) {
            this.selectedOrderNo = '';
            this.selectedOrderNo = no;
        },
        // 判断任务单是否是选中状态
        isActive(no) {
            let b = false;
            if (this.selectedOrderNo === no) {
                b = true;
            }
            return b;
        },
        async setRelation() {
            if (!this.selectedOrderNo) {
                this.$message.warning('请先选择供应单');
                return;
            }
            const res = await this.getTruckClashsApi();
            if (res) {
                this.$message.success('设置成功！');
                this.data.splice(this.data.findIndex(item => item.id === this.activeCar), 1);
                this.supplyList = [];
                if (this.data.length < 1) {
                    this.$parent.showDefault = true;
                } else {
                    this.$parent.showDefault = false;
                }
                this.selectedOrderNo = '';
            }
        },
        // 设置原材料车辆与供应单关联
        getTruckClashsApi() {
            const path = '/interfaceApi/procurement/daily/supply/truck_supply_relation';
            const data = {
                clash_id: this.activeCar,
                task_number: this.selectedOrderNo,
            };
            return this.$axios.$post(path, data, { defEx: true });
        },

    },
};
</script>
<style lang="stylus">
.appointOrder-list
    height: calc(100% - 0.5rem);
    padding .2rem
    background #fff
    position relative
    .appointOrder-list-content
        height calc(100% - 0.6rem)
        overflow-y auto
    .cars
        display flex
        flex-wrap wrap
        .car
            width calc(100%/6 - 0.15rem)
            height 1.51rem
            background url(./../../../assets/images/weighRoom/bg_normal.png) no-repeat
            background-size 100% 100%
            color #333
            padding .2rem
            cursor pointer
            margin-right 0.15rem
            ul
                li
                    margin-bottom .15rem
                    font-size .14rem
                    .iconfont
                        margin-right .1rem
                    &.first
                        font-size .16rem
            &.active
                background url(./../../../assets/images/weighRoom/bg_highlight.png) no-repeat
                background-size 100% 100%
                color #fff
    .list
        margin-top .2rem
        ul
            li
                border 1px solid #D7D7D7
                height .45rem
                margin-bottom .04rem
                display flex
                span
                    flex 1
                    text-align center
                    line-height .45rem
                    overflow hidden
                    text-overflow ellipsis
                    white-space nowrap
                    padding 0 .1rem
                    font-size .15rem
                    .iconfont
                        font-size .2rem
                        color #D7D7D7
                .flex2
                    flex 2
                .chose
                    background #F6FAFD
                .first-td
                    width .8rem
                    flex none
                    cursor pointer
                &.tit
                    background #ECF0F5
                    span
                        font-size .16rem
                        color #022782
                &.active
                    border 2px solid #4A97E5
                    .chose
                        background linear-gradient(270deg,rgba(30,101,213,1) 0%,rgba(75,152,230,1) 100%)
                        .iconfont
                            color #fff
    .btn-con
        position absolute
        bottom .2rem
        text-align center
        width 100%
        .sure-btn
            width 1.4rem
            height .4rem
            line-height .4rem
            text-align center
            color #fff
            background #1577D2
            display inline-block
            cursor pointer
</style>
